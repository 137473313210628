
import { Vue, Component, Model, Prop, Watch } from 'vue-property-decorator';
import FuzzySearch from 'fuzzy-search';
import { Debounce } from 'vue-debounce-decorator';
import { sleep } from '@/utils/helpers';
import fuzzysort from 'fuzzysort';

@Component
export default class AutoSuggestTextarea extends Vue {
  @Model('change', { type: String }) dropdownValues!: any;

  @Prop({ type: String, default: 'What symptoms are you noticing' })
  placeholder!: any;

  @Prop({ type: String, default: 'bg-tasqNeutral1000' }) background!: any;

  @Prop({ type: Number, default: 32 }) height!: any;

  @Prop({ type: Boolean, default: false }) autoRezise!: any;

  @Prop({ type: Boolean, default: true }) matchWholeQuery!: any;

  @Prop({ type: String, default: '' }) triggerCharacter!: any;

  @Prop({ type: Number, default: 460 }) width!: any;

  @Prop({ type: Number, default: -9 }) skidding!: any;

  @Prop({ type: String, default: '' }) borderClass!: any;

  @Prop({ type: Boolean, default: false }) setFocusOnCreate!: any;

  @Prop({
    type: Array,
    default() {
      return [];
    },
  })
  suggestions!: any;

  textInput: string = '';
  showPopup: boolean = false;
  popupTop: number = 0;
  popupLeft: number = 0;
  selectedSuggestionIndex: number = -1;

  userHasTyped: boolean = false;

  setFocus() {
    this.$nextTick(() => {
      if (this.$refs.autoSuggestion) {
        (this.$refs.autoSuggestion as any).focus();
      }
    });

    this.$nextTick(() => {
      if (this.$refs.autoSuggestion) {
        (this.$refs.autoSuggestion as any).focus();
      }
    });
  }

  userInitals(name) {
    let result = '';
    if (name && name.length) {
      result = name.charAt(0);
    }
    if(name && name.length){
      const [firstName, lastName] = name.split(' ');
    if (firstName && lastName) {
      return firstName.charAt(0) + lastName.charAt(0);
    }

    }

    return result;
  }

  get isPopupOpen() {
    return this.showPopup && this.userHasTyped && !!this.currentSuggestions.length;
  }

  @Debounce(1500)
  autoResizeTextareaOnInput(){
    this.autoResizeTextarea()
  }

  autoResizeTextarea() {
    if (this.autoRezise) {
      this.$nextTick(() => {
        const elements = this.$refs;
        if (elements && Object.keys(elements)) {
          Object.keys(elements).forEach((key) => {
            if (elements[key] && elements[key]['$el']) {
              const target = elements[key]['$el'];
              if (target.scrollHeight > 34) {
                target.style.height = 'auto';
                target.style.height = `${target.scrollHeight}px`;
              }
            }
          });
        }
      });
    }
  }

  created() {
    this.textInput = this.dropdownValues;
    this.autoResizeTextarea();
    if (this.setFocusOnCreate) {
      this.setFocus();
    }
  }

  get currentSuggestions() {
    let query = '';

    // this.matchWholeQuery
    //   ? this.textInput.trim()
    //   : this.textInput.trim().split(' ').pop();

    if (this.matchWholeQuery) {
      query = this.textInput.trim();
    } else {
      query = this.textInput.slice(0, this.cursorPosition);
    }

    if (this.triggerCharacter && query.includes(this.triggerCharacter)) {
      const termAfterTrigger = query.split(this.triggerCharacter).pop();
      if (query === this.triggerCharacter) {
        this.showPopup = true;
        return this.suggestions;
      }

      let results = fuzzysort.go(termAfterTrigger, this.suggestions, { threshold: -50})
        function getObj(obj) {
          return obj.target;
        }
        return results.map((t) => getObj(t))

    } else if (!this.triggerCharacter) {
      let results = fuzzysort.go(query, this.suggestions, { threshold: -50})
        function getObj(obj) {
          return obj.target;
        }
        return results.map((t) => getObj(t))
    }

    return [];
  }

  getCursorPosition(): number {
    const sel = window.getSelection();
    if (sel && sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      return range.startOffset;
    }
    return 0;
  }
  cursorPosition = 0;

  onTextInput() {
    // Show popup if there are matching suggestions
    this.showPopup = this.currentSuggestions.length > 0;
    this.$emit('change', this.textInput);
    this.$emit('input', this.textInput);
  }

  handleKeyDown(event: KeyboardEvent) {
    this.userHasTyped = true;

    // Get the textarea element
    const textarea: any = event.target;
    // Get the cursor position
    this.cursorPosition = textarea.selectionStart;

    if (this.showPopup) {
      // If there are more than 3 items and nothing is selected yet, default to the third item

      switch (event.key) {
        case 'ArrowUp':
          // Prevent the default behavior to keep the cursor in place
          event.preventDefault();
          if (this.selectedSuggestionIndex === -1) {
            this.selectedSuggestionIndex = this.currentSuggestions.length > 3 ? 3 : this.currentSuggestions.length;
          }

          this.selectedSuggestionIndex = Math.max(0, this.selectedSuggestionIndex - 1);

          break;
        case 'ArrowDown':
          // Prevent the default behavior to keep the cursor in place
          event.preventDefault();
          // Prevent index from going beyond the last suggestion
          this.selectedSuggestionIndex = Math.min(this.currentSuggestions.length - 1, this.selectedSuggestionIndex + 1);
          break;
        case 'Enter':
        case 'Tab':
          event.preventDefault(); // Prevent form submission
          if (this.currentSuggestions.length > 0) {
            if (this.selectedSuggestionIndex === -1) {
              // If no suggestion is selected, default to the first suggestion

              this.useSuggestion(this.currentSuggestions[0]);
            } else {
              // Use the selected suggestion
              if (this.currentSuggestions[this.selectedSuggestionIndex]) {
                this.useSuggestion(this.currentSuggestions[this.selectedSuggestionIndex]);
              } else if (this.currentSuggestions[this.selectedSuggestionIndex]) {
                this.useSuggestion(this.currentSuggestions[this.selectedSuggestionIndex]);
              }
            }
            this.showPopup = false;
          }
          break;
        case 'Escape':
          event.preventDefault(); // Hide the suggestion popup
          this.showPopup = false;
          break;
      }
    } else {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent form submission
        if (!(this.showPopup && this.userHasTyped && this.currentSuggestions.length > 0)) {
          this.$emit('add-item');
        }
      }
    }
    this.setFocus();
  }

  useSuggestion(suggestion: string) {
    // Check if the matchWholeQuery flag is set
    this.$emit('get-suggestions', this.textInput);
    if (this.matchWholeQuery) {
      // If matchWholeQuery is true, replace the entire text input with the selected suggestion
      this.textInput = suggestion;
    } else if (this.triggerCharacter && this.textInput.includes(this.triggerCharacter)) {
      // If triggerCharacter exists in textInput, keep everything before it (including the triggerCharacter itself) and // 2. Find the last occurrence of the `triggerCharacter` before the cursor position
      const textBeforeCursor = this.textInput.substring(0, this.cursorPosition);
      // console.log(textBeforeCursor);
      const lastIndex = textBeforeCursor.lastIndexOf(this.triggerCharacter);

      if (lastIndex !== -1) {
        // 3. Replace the text after that `triggerCharacter` with the suggestion
        this.textInput = this.textInput.substring(0, lastIndex + this.triggerCharacter.length) + suggestion;
      }
    } else {
      // If matchWholeQuery is false, replace only the last word of the text input with the selected suggestion
      const words = this.textInput.split(' ');
      words[words.length - 1] = suggestion;
      this.textInput = words.join(' ');
    }

    // Add a space at the end to allow the user to continue typing
    this.textInput += ' ';

    // Hide the suggestion popup
    this.showPopup = false;

    // Reset the selected suggestion index
    this.selectedSuggestionIndex = -1;
    this.userHasTyped = false;
  }

  @Watch('isPopupOpen')
  async setIndexValue(value) {
    if (value) {
      if (this.currentSuggestions.length) {
        // this.currentSuggestions.length > 3
        //   ? (this.selectedSuggestionIndex = 2)
        //   : this.currentSuggestions.length;
      }
    } else {
      this.selectedSuggestionIndex = -1;
    }

    await sleep(100);
    this.setFocus();
  }

  @Watch('currentSuggestions')
  async setIndexValueOnSuggestionUpdate(value) {
    if (this.isPopupOpen) {
      // this.selectedSuggestionIndex = Math.max(
      //   0,
      //   this.currentSuggestions.length > 3 ? 2 :  this.currentSuggestions.length - 1
      // );
    }
  }

  scrollToSelectedSuggestion() {
    this.$nextTick(() => {
      const container: any = this.$refs.scrollContainer;
      const selectedItem = this.$refs[`suggestionItem-${this.selectedSuggestionIndex}`][0];

      if (container && selectedItem) {
        const containerRect = container.getBoundingClientRect();
        const selectedItemRect = selectedItem.getBoundingClientRect();

        if (selectedItemRect.top < containerRect.top || selectedItemRect.bottom > containerRect.bottom) {
          container.scrollTop += selectedItemRect.top - containerRect.top;
        }
      }
    });
  }

  @Watch('selectedSuggestionIndex')
  UpdateSelectedSuggestionIndex(val) {
    if (val > -1) {
      this.scrollToSelectedSuggestion();
    }
  }

  @Watch('textInput')
  onTextInputChange(value: string) {
    if (value.length < 2) {
      this.showPopup = false;
    } else {
      this.showPopup = this.currentSuggestions.length > 0;
      this.$emit('get-suggestions', this.textInput);
      this.$emit('change', this.textInput);
      this.$emit('input', this.textInput);
    }
    if (value.length === 0) {
      this.$emit('change', this.textInput);
    }
  }
}
